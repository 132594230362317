import React from 'react';
import ErrorPage from './index';
import { Route } from 'react-router-dom';

function NotFoundPage({ statusCode = 404 }) {

  return (
    <Route
      render={({ staticContext, location }) => {
        if (staticContext) {
          staticContext.statusCode = statusCode;
        }

        if (location && location.pathname.startsWith('/shows/')) {
          return (
            <ErrorPage
              title='We’re taking a break'
              pageHeader='We’re taking a break'
              bodyText='Don’t worry, this show will be back soon. In the meantime, why not check out these other tastylive shows?'
              homeButton={false}
              onDemandButton={true}
            />
          );
        }

        return (
          <ErrorPage
            title='404 Not Found'
            heroHeader='404 Not Found'
            pageHeader='Page Could Not Be Found'
            bodyText='The page you were trying to reach cannot be found.'
            homeButton={true}
            onDemandButton={true}
          />
        );
      }}
    />
  );
}

export default NotFoundPage;
